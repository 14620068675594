// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../components/SEO'
import ProfileContianer from '../../containers/ProfileContainer'
import LayoutMain from '../../layout'

const Profile: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Información General" />
      <WithPrivateRoute component={ProfileContianer} />
    </LayoutMain>
  )
}

export default Profile
